import { post } from '@/http'

// 我的订单列表
export function getMyOrder(data) {
    return new Promise((resolve, reject) => {
        post('/Order/GetMyOrderEvaList', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}
// 获取订单详情
export function getOrderInfo(data) {
    return new Promise((resolve, reject) => {
        post('/Order/GetOrderInfo', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}
