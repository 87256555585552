<!--我的订单-->
<template>
    <div>
        <el-tabs
            v-model="activeName"
            @tab-click="chaneTabs"
            @change="chaneTabs"
        >
            <el-tab-pane>
                <span slot="label">未评价</span>
                <div class="list">
                    <div>
                        <table border="0" cellspacing="0" cellpadding="0">
                            <tr class="list-title">
                                <th width="450">商品信息</th>
                                <th width="110">单价(元)</th>
                                <th width="110">数量</th>
                                <th width="110">商品操作</th>
                                <th width="110">实付款(元)</th>
                                <th width="110">全部状态</th>
                                <th width="160">交易操作</th>
                            </tr>
                        </table>

                        <!--订单号-->
                        <div v-if="cartList.length > 0">
                            <table
                                style="border-collapse:separate; border-spacing:0 10px;"
                                border="0"
                                cellspacing="0"
                                cellpadding="0"
                                v-for="(item, index) in cartList"
                                :key="index"
                            >
                                <tr class="order-mian" style="margin:10px">
                                    <td
                                        width="450"
                                        style="margin:10px;    padding-left: 10px;"
                                    >
                                        {{ item.CreatTime }} 订单号
                                        <span class="order-number">
                                            {{ item.OrderID }}</span
                                        >
                                    </td>
                                    <td
                                        width="110"
                                        v-for="j in 5"
                                        :key="j"
                                    ></td>
                                    <td width="160"></td>
                                </tr>
                                <tr
                                    class="order-mian-list"
                                    v-for="(j, i) in item.ListData"
                                    :key="i"
                                >
                                    <td style="display: flex" class="border">
                                        <div style="height:100px">
                                            <img
                                                style="cursor: pointer;"
                                                @click="handleTo(j.detail)"
                                                :src="pic + j.detail.ProPictrue"
                                                alt=""
                                            />
                                        </div>
                                        <div class="order-mian-list-text">
                                            <div class="proName">
                                                {{ j.detail.ProName }}
                                            </div>
                                            <div class="sku">
                                                {{ j.detail.BarCode }}
                                            </div>
                                        </div>
                                    </td>
                                    <td class="border">
                                        <div>￥{{ j.detail.ProUnitPrice }}</div>
                                    </td>
                                    <td class="border">
                                        <div>X {{ j.detail.PCount }}</div>
                                    </td>
                                    <td class="border-right">
                                        <div class="operation"></div>
                                        <div
                                            style="color: #DC2310"
                                            v-if="j.detail.ExState == 6"
                                        >
                                            退款中
                                        </div>
                                        <div
                                            style="color: #DC2310"
                                            v-if="j.detail.ExState == 7"
                                        >
                                            退款完成
                                        </div>
                                        <div
                                            style="color: #DC2310"
                                            v-if="j.detail.ExState == 8"
                                        >
                                            退款失败
                                        </div>
                                        <div
                                            style="color: #DC2310"
                                            v-if="j.detail.ExState == 10"
                                        >
                                            退换审核失败
                                        </div>
                                        <el-button
                                            size="mini"
                                            v-if="
                                                (item.State == 1 ||
                                                    item.State == 2 ||
                                                    item.State == 3) &&
                                                    j.detail.ExState == 0
                                            "
                                            @click="refund(j.detail)"
                                            >申请售后</el-button
                                        >
                                        <el-button
                                            size="mini"
                                            v-if="
                                                item.State == 4 &&
                                                    item.show &&
                                                    j.detail.ExState == 0
                                            "
                                            @click="refund(j.detail)"
                                            >申请售后</el-button
                                        >
                                        <div
                                            v-if="j.ExState != 0"
                                            @click="view(j.detail)"
                                            style="margin-top:10px;    cursor: pointer;"
                                        >
                                            售后详情
                                        </div>
                                    </td>
                                    <td
                                        class="border-right"
                                        :rowspan="item.ListData.length"
                                        v-if="i == 0"
                                    >
                                        <div class="subtotal">
                                            ￥{{ item.TotalPrice.toFixed(2) }}
                                        </div>
                                        <div class="button">
                                            <span v-if="item.OrderType == 1"
                                                >手机订单</span
                                            >
                                            <span v-else>电脑订单</span>
                                        </div>
                                    </td>
                                    <td
                                        class="border-right"
                                        :rowspan="item.ListData.length"
                                        v-if="i == 0"
                                    >
                                        <div>
                                            <span
                                                v-if="item.State == 0"
                                                class="stay"
                                            >
                                                待付款</span
                                            >
                                            <span
                                                v-if="item.State == 1"
                                                style="color: #1B98AA; "
                                                >待发货</span
                                            >
                                            <span
                                                v-if="item.State == 2"
                                                style="color: #1B98AA; "
                                                >卖家已发货</span
                                            >
                                            <span
                                                v-if="item.State == 3"
                                                style="color: #999999;"
                                                >待评价</span
                                            >
                                            <span
                                                v-if="item.State == 4"
                                                style="color: #999999;"
                                                >已完成</span
                                            >
                                            <span
                                                v-if="item.State == 5"
                                                class="cancel-order"
                                            >
                                                已取消</span
                                            >
                                        </div>
                                        <div
                                            class="order-details"
                                            @click="toOrderDetails(item)"
                                        >
                                            订单详情
                                        </div>
                                        <el-popover
                                            placement="bottom"
                                            width="600"
                                            @show="showLogistics(item)"
                                            trigger="click"
                                        >
                                            <div class="information">
                                                <div class="information-title">
                                                    {{ information.ComName }}
                                                </div>
                                                <div
                                                    class="information-main"
                                                    v-for="(item,
                                                    index) in information.TrackList"
                                                    :key="index"
                                                >
                                                    <div>
                                                        {{ item.context }}
                                                        {{ item.ftime }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                slot="reference"
                                                class="track"
                                                v-if="item.State == 2"
                                            >
                                                <i
                                                    class="iconfont iconwuliu"
                                                ></i>
                                                <span>追踪</span>
                                            </div>
                                        </el-popover>
                                    </td>
                                    <td
                                        class="border-right"
                                        :rowspan="item.ListData.length"
                                        v-if="i == 0"
                                    >
                                        <div v-if="item.State == 0">
                                            <div class="paidui">
                                                <i
                                                    class="iconfont iconpaidui"
                                                ></i
                                                ><span>{{ item.time }}</span>
                                            </div>
                                            <div
                                                class="handle"
                                                @click="handlePay(item)"
                                            >
                                                支付
                                            </div>
                                            <div
                                                class="cancel"
                                                @click="cancel(item)"
                                            >
                                                取消订单
                                            </div>
                                        </div>
                                        <div
                                            class="remind"
                                            @click="remind(item)"
                                            v-if="item.State == 1"
                                        >
                                            提醒发货
                                        </div>
                                        <div v-if="item.State == 2">
                                            <div class="paidui">
                                                <i
                                                    class="iconfont iconpaidui"
                                                ></i
                                                ><span>{{
                                                    item.confirmTime
                                                }}</span>
                                            </div>
                                            <div
                                                @click="confirm(item)"
                                                class="confirm"
                                            >
                                                确认收货
                                            </div>
                                        </div>
                                        <div v-if="item.State == 3">
                                            <div
                                                @click="toEvaluate(item)"
                                                class="confirm"
                                            >
                                                去评价
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="pagination">
                        <div>
                            <el-pagination
                                background
                                @size-change="changeSize"
                                @current-change="changeCurrent"
                                :page-sizes="[10, 50, 100, 200]"
                                :page-size="20"
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="count"
                            >
                            </el-pagination>
                        </div>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane>
                <span slot="label">已评价</span>
                <div class="list">
                    <div>
                        <table border="0" cellspacing="0" cellpadding="0">
                            <tr class="list-title">
                                <th width="550">商品信息</th>
                                <th width="210">评分</th>
                                <th width="410">评价内容</th>
                            </tr>
                        </table>

                        <!--订单号-->
                        <div v-if="cartList.length > 0">
                            <table
                                style="border-collapse:separate; border-spacing:0 10px;"
                                border="0"
                                cellspacing="0"
                                cellpadding="0"
                                v-for="(item, index) in cartList"
                                :key="index"
                            >
                                <tr
                                    class="order-mian"
                                    style="margin:10px;    padding-left: 10px;"
                                >
                                    <td
                                        width="450"
                                        style="margin:10px;    padding-left: 10px;"
                                    >
                                        {{ item.CreatTime }} 订单号
                                        <span class="order-number">
                                            {{ item.OrderID }}</span
                                        >
                                    </td>
                                    <td width="80"></td>
                                    <td width="80"></td>
                                    <td width="180"></td>
                                    <td width="380"></td>
                                </tr>
                                <tr
                                    class="order-mian-list"
                                    v-for="(j, i) in item.ListData"
                                    :key="i"
                                >
                                    <td style="display: flex" class="border">
                                        <div style="height:100px">
                                            <img
                                                style="cursor: pointer;margin-left:10px"
                                                @click="handleTo(j.detail)"
                                                :src="pic + j.detail.ProPictrue"
                                                alt=""
                                            />
                                        </div>
                                        <div class="order-mian-list-text">
                                            <div class="proName">
                                                {{ j.detail.ProName }}
                                            </div>
                                            <div class="sku">
                                                {{ j.detail.BarCode }}
                                            </div>
                                        </div>
                                    </td>
                                    <td class="border">
                                        <div>￥{{ j.detail.ProUnitPrice }}</div>
                                    </td>

                                    <td class="border">
                                        <div>X {{ j.detail.PCount }}</div>
                                    </td>
                                    <td class="border" v-if="j.Eva[0]">
                                        <el-rate
                                            v-model="j.Eva[0].ProductStar"
                                            disabled
                                            show-score
                                            text-color="#ff9900"
                                            score-template="{value}"
                                        >
                                        </el-rate>
                                        <div
                                            style="color: #999999;margin-top:10px"
                                        >
                                            {{ j.Eva[0].CreatTime }} 已评
                                        </div>
                                    </td>
                                    <td
                                        v-if="j.Eva[0]"
                                        class="border-right"
                                        style="padding: 0 30px;"
                                    >
                                        <div>
                                            {{ j.Eva[0].Detail }}
                                        </div>
                                        <div
                                            v-if="j.Eva[0].Photos != ''"
                                            style="    height: 90px;
    overflow: hidden;
}"
                                        >
                                            <el-image
                                                v-for="(item,
                                                key) in j.Eva[0].Photos.split(
                                                    ','
                                                )"
                                                :key="key"
                                                style="width: 100px; height: 100px"
                                                :src="pic + item"
                                            ></el-image>
                                        </div>
                                    </td>

                                    <!-- 
                                    <td class="border-right">
                                        <div class="operation"></div>
                                        <div
                                            style="color: #DC2310"
                                            v-if="j.ExState == 6"
                                        >
                                            退款中
                                        </div>
                                        <div
                                            style="color: #DC2310"
                                            v-if="j.ExState == 7"
                                        >
                                            退款完成
                                        </div>
                                        <div
                                            style="color: #DC2310"
                                            v-if="j.ExState == 8"
                                        >
                                            退款失败
                                        </div>
                                        <div
                                            style="color: #DC2310"
                                            v-if="j.ExState == 10"
                                        >
                                            退换审核失败
                                        </div>
                                        <el-button
                                            size="mini"
                                            v-if="
                                                (item.State == 1 ||
                                                    item.State == 2 ||
                                                    item.State == 3) &&
                                                    j.ExState == 0
                                            "
                                            @click="refund(j)"
                                            >申请售后</el-button
                                        >
                                        <el-button
                                            size="mini"
                                            v-if="
                                                item.State == 4 &&
                                                    item.show &&
                                                    j.ExState == 0
                                            "
                                            @click="refund(j)"
                                            >申请售后</el-button
                                        >
                                        <div
                                            v-if="j.ExState != 0"
                                            @click="view(j)"
                                            style="margin-top:10px;    cursor: pointer;"
                                        >
                                            售后详情
                                        </div>
                                    </td>

                                    <td
                                        class="border-right"
                                        :rowspan="item.ListData.length"
                                        v-if="i == 0"
                                    >
                                        <div v-if="item.State == 0">
                                            <div class="paidui">
                                                <i
                                                    class="iconfont iconpaidui"
                                                ></i
                                                ><span>{{ item.time }}</span>
                                            </div>
                                            <div
                                                class="handle"
                                                @click="handlePay(item)"
                                            >
                                                支付
                                            </div>
                                            <div
                                                class="cancel"
                                                @click="cancel(item)"
                                            >
                                                取消订单
                                            </div>
                                        </div>
                                        <div
                                            class="remind"
                                            @click="remind(item)"
                                            v-if="item.State == 1"
                                        >
                                            提醒发货
                                        </div>
                                        <div v-if="item.State == 2">
                                            <div class="paidui">
                                                <i
                                                    class="iconfont iconpaidui"
                                                ></i
                                                ><span>{{
                                                    item.confirmTime
                                                }}</span>
                                            </div>
                                            <div
                                                @click="confirm(item)"
                                                class="confirm"
                                            >
                                                确认收货
                                            </div>
                                        </div>
                                        <div v-if="item.State == 3">
                                            <div
                                                @click="toEvaluate(item)"
                                                class="confirm"
                                            >
                                                去评价
                                            </div>
                                        </div>
                                    </td> -->
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="pagination">
                        <div>
                            <el-pagination
                                background
                                @size-change="changeSize"
                                @current-change="changeCurrent"
                                :page-sizes="[10, 50, 100, 200]"
                                :page-size="20"
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="count"
                            >
                            </el-pagination>
                        </div>
                    </div>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import { getMyOrder } from './service'
import {
    agreenOrder,
    cancleOrder,
    queryInformation,
    sendRemind
} from '../payment/service'

export default {
    name: 'order',
    data() {
        return {
            value: 3,
            activeName: '',
            count: 0,
            information: [],
            parameter: {
                State: 3,
                PageIndex: 1,
                PageSize: 10
            },
            pic: FILE_URL,
            cartList: []
        }
    },
    mounted() {
        this.init()
        setInterval(() => {
            this.cartList.splice(0, 0)
        }, 1000)
    },
    methods: {
        // tabs切换
        chaneTabs() {
            switch (this.activeName) {
                case '0':
                    this.parameter.State = 3
                    break
                case '1':
                    this.parameter.State = 4
                    break
            }
            this.init()
        },
        // 订单详情
        toOrderDetails(data) {
            window.open(`/me/orderDetails?OrderID=${data.OrderID}`, '_blank')
                .location
        },
        // 去评价
        toEvaluate(data) {
            this.$router.push({
                path: `/me/toEvaluate?OrderID=${data.OrderID}`
            })
        },
        // 确认收货
        confirm(data) {
            agreenOrder({
                OrderID: data.OrderID
            }).then(() => {
                this.$message.success('收货完成')
                this.init()
            })
        },
        // 提醒发货
        remind(data) {
            sendRemind({
                OrderID: data.OrderID
            }).then(() => {
                this.$message.success('提醒成功')
                this.init()
            })
        },
        // 查询物流
        showLogistics(data) {
            if (!data.TrackingNum) {
                this.$message.error('暂无没有物流信息')
                return
            }
            queryInformation({
                OrderID: data.OrderID
            }).then(res => {
                this.information = res.data
            })
        },
        // 取消订单
        cancel(data) {
            cancleOrder({
                OrderID: data.OrderID
            }).then(() => {
                this.$message.success('取消成功')
                this.init()
            })
        },
        view(j) {
            this.$router.push({
                path: `/me/goodsDetails?OrderID=${j.ExOrderID}`
            })
        },
        refund(j) {
            this.$router.push({
                path: `/typeGoods?CreatTime=${j.CreatTime}&OrderID=${j.OrderID}&ProName=${j.ProName}&ProUnitPrice=${j.ProUnitPrice}&PCount=${j.PCount}&DisPrice=${j.DisPrice}&LastPrice=${j.LastPrice}&ProPictrue=${j.ProPictrue}&BarCode=${j.BarCode}&TaxPrice=${j.TaxPrice}&PID=${j.PID}`
            })
        },
        handlePay(data) {
            this.$router.push({
                path: `/payment?orderID=${data.OrderID}`
            })
        },
        changeSize(size) {
            this.parameter.PageSize = size
            this.init()
        },
        changeCurrent(size) {
            this.parameter.PageIndex = size
            this.init()
        },
        init() {
            getMyOrder(this.parameter).then(res => {
                for (let item in res.data) {
                    res.data[item].time = '00小时00分00秒'
                    res.data[item].confirmTime = '00小时00分00秒'
                    if (res.data[item].State == 0) {
                        setInterval(() => {
                            let dateTime = new Date(res.data[item].CreatTime)
                            dateTime = dateTime.setDate(dateTime.getDate() + 1)
                            dateTime = new Date(dateTime)
                            res.data[item].time = this.showtime(dateTime)
                        }, 1000)
                    }
                    if (res.data[item].State == 2) {
                        setInterval(() => {
                            let dateTime = new Date(
                                res.data[item].ShipmentsTime
                            )
                            dateTime = dateTime.setDate(dateTime.getDate() + 12)
                            dateTime = new Date(dateTime)
                            res.data[item].confirmTime = this.showtime(dateTime)
                        }, 1000)
                    }
                    if (res.data[item].State == 4) {
                        this.order = res.data
                        var dateTime = new Date(res.data[item].OverTime)
                        var dateTime1 = new Date()
                        dateTime = dateTime.setDate(dateTime.getDate() + 14)
                        dateTime = new Date(dateTime)

                        if (dateTime < dateTime1) {
                            res.data[item].show = false
                        } else {
                            res.data[item].show = true
                        }
                    }
                }
                this.cartList = res.data
                this.count = res.count
            })
        },
        handleTo(data) {
            let u = localStorage.getItem('u')
            window.open(`/commodity?pid=${data.PID}&u=${u}`, '_blank').location
        },
        showtime(data) {
            let nowtime = new Date(), //获取当前时间
                endtime = new Date(data) //定义结束时间
            let lefttime = endtime.getTime() - nowtime.getTime(), //距离结束时间的毫秒数
                days = parseInt(lefttime / 1000 / 60 / 60 / 24), //计算剩余的天数
                lefth = Math.floor((lefttime / (1000 * 60 * 60)) % 24), //计算小时数
                leftm = Math.floor((lefttime / (1000 * 60)) % 60), //计算分钟数
                lefts = Math.floor((lefttime / 1000) % 60) //计算秒数
            if (lefts.length == 1) {
                lefts = '0' + lefts
            }
            lefth = lefth >= 0 && lefth < 10 ? '0' + lefth : '' + lefth
            leftm = leftm >= 0 && leftm < 10 ? '0' + leftm : '' + leftm
            lefts = lefts >= 0 && lefts < 10 ? '0' + lefts : '' + lefts
            this.time = lefth + '小时' + leftm + '分' + lefts + '秒'
            if (lefth.startsWith('-')) {
                return '已作废'
            } else {
                return (
                    days + '天' + lefth + '小时' + leftm + '分' + lefts + '秒'
                )
            }
        }
    }
}
</script>
<style lang="less" scoped>
.sku {
    width: fit-content;
    padding: 2px 10px;
    background: #f5f5f5;
    border: 1px solid #dedede;
    border-radius: 15px;
    color: #333333;
    font-size: 12px;
    margin: 10px 0px;
}
.information {
    text-align: center;

    .information-title {
        color: #666666;
        padding-bottom: 10px;
        border-bottom: 1px solid #f4f4f4;
    }

    .information-main {
        margin-top: 20px;
        color: #999999;
    }
}

.confirm {
    background: #1b98aa;
    color: #ffffff;
    width: 80px;
    padding: 5px 0;
    margin: 10px auto;
    cursor: pointer;
}

.stay {
    color: #ff2323;
}

.track {
    margin-top: 12px;
    cursor: pointer;

    i {
        margin-right: 3px;

        span {
            margin-top: -10px;
        }
    }
}

.pagination {
    display: flex;
    margin-top: 30px;
    justify-content: flex-end;
    margin-right: 30px;
}

.search {
    display: flex;

    input {
        background: #eeeeee;
        border: none;
        outline: none;
        width: 250px;
        height: 35px;
        text-indent: 1rem;
    }

    .icon {
        width: 37px;
        height: 37px;
        line-height: 37px;
        background: #eeeeee;
        margin-left: 3px;
        text-align: center;

        i {
            font-size: 18px;
            color: #666666;
        }
    }

    .select {
        margin-left: 20px;
    }
}

.list {
    margin-top: 10px;

    .list-title {
        background: #eeeeee;
        height: 40px;
        text-align: center;
    }

    .order-mian {
        width: 1300px;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
        margin: 10px 0;
        background: #f9f9f9;
        padding-left: 10px;
        box-sizing: border-box;
    }

    .order-mian-list {
        text-align: center;
        font-size: 14px;
        margin: 10px 0;
    }

    .order-mian-list-text {
        text-align: left;
        margin: 10px;
    }

    .subtotal {
        font-family: 'ct';
        color: #1b98aa;
        font-size: 17px;
    }

    .button {
        width: fit-content;
        padding: 2px 10px;
        margin: 10px auto;
        background: rgba(27, 152, 170, 0);
        border: 1px solid #d9d9d9;
        color: #999999;
    }

    img {
        width: 100px;
        height: 100px;
    }

    .border {
        padding-bottom: 10px;
        border-bottom: 1px solid #f4f4f4;
    }

    .border-left {
        border-left: 1px solid #f4f4f4;
    }

    .border-right {
        border-right: 1px solid #f4f4f4;
        border-bottom: 1px solid #f4f4f4;
    }

    .order-details {
        margin-top: 30px;
        cursor: pointer;
    }

    .paidui {
        color: #999999;

        span {
            margin-left: 5px;
        }
    }

    .handle {
        width: fit-content;
        margin: 10px auto;
        padding: 5px 15px;
        color: #ffffff;
        cursor: pointer;
        background: #dc2310;
    }
}

tr {
    margin: 10px;
}

.cancel {
    cursor: pointer;
}

.cancel-order {
    color: #999999;
}

.remind {
    cursor: pointer;
}
.proName {
    width: 200px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 14px;
}
</style>

<style scoped>
>>> .el-input__inner {
    border-radius: 0;
}

>>> .el-pagination.is-background .el-pager li:not(.disabled).active {
    background: #1b98aa;
}

>>> .el-tabs {
    width: 1066px;
}

>>> .el-tabs__item {
    font-family: 'ct';
    font-size: 15px;
    color: #333333;
}

>>> .el-tabs__item.is-active {
    color: #1b98aa;
}

>>> .el-tabs__nav-wrap::after {
    height: 1px;
}

>>> .el-tabs__active-bar {
    height: 3px;
    background: linear-gradient(50deg, #60b689, #00b5c2, #0897b4);
}
</style>
